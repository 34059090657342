<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column" cols="12" xl="4">
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar :src="typeof userData.profilePictureFileUrl === 'string' ? userData.profilePictureFileUrl : organizationUser.avatar" :text="avatarText(organizationUser.name)" :variant="`light-${resolveRoleVariant(organizationUser.workrole)}`" rounded size="104px" />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ organizationUser.name }}
              </h4>
              <span class="card-text">{{ organizationUser.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="organizationUser.id !== selectedOrganization.userId"
                :to="{ name: 'user-organization-edit', params: { organization_id: $route.params.organization_id, user_id: organizationUser.id } }"
              >
                {{ $t('action.edit') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" lg="3">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon class="mr-75" icon="CheckIcon" />
              <span class="font-weight-bold">{{ $t('user.status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge :variant="`light-${resolveStatusVariant(organizationUser.status)}`" class="text-capitalize" pill>
                {{ organizationUser.status|enumTranslate('user_status') }}
              </b-badge>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon class="mr-75" icon="StarIcon" />
              <span class="font-weight-bold">{{ $t('user.role') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ organizationUser.workrole }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState } from 'vuex'
import UIHelper from '@/helpers/ui'

export default {
  name: 'UserDetailsInfoCard',

  mixins: [UIHelper],
  props: {
    organizationUser: {
      type: Object,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['selectedOrganization', 'user']),
  },
}
</script>

<style></style>
